import MuiLink from "@mui/material/Link";
import { Link as GatsbyLink } from "gatsby";
import React, { forwardRef } from "react";

const hasProtocol = (x: string) =>
    /^(https?|tel|mailto|geo|file|data):/.test(x);

/**
 * Link wraps the MUI Link element but will use the Gatsby Link component for
 * internal links, and apply rel=noopener to external links if rel is not
 * already defined.
 */
export const Link = forwardRef<any, any>(({ href, children, ...rest }, ref) => {
    return hasProtocol(href) || href == null ? (
        // external link
        <MuiLink href={href} rel={rest.rel ?? "noopener"} {...rest} ref={ref}>
            {children}
        </MuiLink>
    ) : (
        // internal link
        <MuiLink to={href} component={GatsbyLink} {...rest} ref={ref}>
            {children}
        </MuiLink>
    );
});

export const UnthemedLink = forwardRef<any, any>(
    ({ href, children, ...rest }, ref) => {
        return hasProtocol(href) || href == null ? (
            // external link
            <MuiLink
                href={href}
                rel={rest.rel ?? "noopener"}
                {...rest}
                ref={ref}
            >
                {children}
            </MuiLink>
        ) : (
            // internal link
            <GatsbyLink to={href} {...rest} ref={ref}>
                {children}
            </GatsbyLink>
        );
    },
);
