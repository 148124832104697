import { Components } from "@mui/material/styles/components";
import { hexToRgba } from "../functions";
import { Colors } from "./colors";
import { OgilvieFont } from "./typography";
import { Link } from "../components/core/Link";
import { alpha } from "@mui/material";
import { KeyboardArrowDownRounded } from "@mui/icons-material";

// Module Augmentations
declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        gradient: true;
        white: true;
    }
}

export const components: Components = {
    MuiAccordion: {
        defaultProps: {
            variant: "outlined",
        },
    },
    MuiAppBar: {
        defaultProps: {
            elevation: 0,
            color: "transparent",
            position: "sticky",
        },
    },
    MuiButton: {
        defaultProps: {
            variant: "gradient",
            disableElevation: true,
            LinkComponent: Link,
        },
        styleOverrides: {
            root: {
                fontFamily: OgilvieFont.SemiBold,
            },
            outlined: {
                borderColor: alpha(Colors.Black, 0.8),
                color: alpha(Colors.Black, 0.8),
                "&:hover, &:focus": {
                    borderColor: Colors.Orange,
                    color: Colors.Orange,
                },
            },
        },
        variants: [
            {
                props: {
                    variant: "gradient",
                },
                style: {
                    background: Colors.LinearGradient,
                    backgroundColor: Colors.White,
                    color: Colors.White,
                    transition: "0.2s ease-in-out",
                    "&:hover, &:focus": {
                        backgroundColor: Colors.White,
                        color: Colors.White,
                        background: Colors.LinearGradientDarkened,
                        transition: "0.2s ease-in-out",
                    },
                },
            },
            {
                props: {
                    variant: "white",
                },
                style: {
                    backgroundColor: Colors.White,
                    color: Colors.Black,
                    "&:hover, &:focus": {
                        backgroundColor: alpha(Colors.White, 0.8),
                        color: Colors.Black,
                    },
                },
            },
        ],
    },
    MuiCard: {
        defaultProps: {
            elevation: 0,
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: 8,
                marginRight: 6,
                backgroundColor: hexToRgba(Colors.LightGrey, 0.7),
                height: 28,
            },
            label: {
                paddingLeft: 8,
                paddingRight: 8,
            },
        },
    },
    MuiGrid: {
        defaultProps: {
            spacing: { xs: 4, md: 2, lg: 4 },
        },
    },
    MuiPaper: {
        defaultProps: {
            elevation: 0,
        },
    },
    MuiSelect: {
        defaultProps: {
            variant: "outlined",
            size: "small",
            native: true,
            IconComponent: KeyboardArrowDownRounded,
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                fontSize: "0.9rem !important",
                fontFamily: OgilvieFont.SemiBold,
                borderRadius: 100,
                padding: 0,
                borderColor: Colors.LightGrey,
            },
        },
    },
    MuiNativeSelect: {
        styleOverrides: {
            select: {
                padding: "6px 28px 4px 12px !important",
            },
        },
    },
    MuiAlertTitle: {
        styleOverrides: {
            root: {
                // important overrides responsive font sizing
                fontSize: "1.2rem !important",
            },
        },
    },
};
