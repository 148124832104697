import { createTheme, responsiveFontSizes, ThemeOptions } from "@mui/material";
import { components } from "./components";
import { palette, darkPalette } from "./palettes";
import { mainTypography, gradientTypography } from "./typography";

const spacing = 8;

const shape = {
    borderRadius: 12,
};

const mainOptions: ThemeOptions = {
    palette,
    spacing,
    shape,
    typography: mainTypography,
    components,
};

const gradientOptions: ThemeOptions = {
    palette: darkPalette,
    spacing,
    shape,
    typography: gradientTypography,
    components,
};

export const theme = responsiveFontSizes(createTheme(mainOptions));
export const gradientTheme = responsiveFontSizes(createTheme(gradientOptions));
