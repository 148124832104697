export enum Colors {
    NavyBlue = "#121A50",
    Purple = "#802b6a",
    Rose = "#BE4452",
    Orange = "#eb660c",
    Cream = "#F0835F",
    LightOrange = "#FF7745",
    White = "#FFFFFF",
    Black = "#000000",
    LightGrey = "#EDEDED",
    DarkGrey = "#C6C6C6",
    Transparent = "rgba(255,255,255,0)",
    LinearGradient = "linear-gradient(125deg, #eb660c, #b93d50 , #802b6a)",
    LinearGradientDarkened = "linear-gradient(125deg, #DE5900, #AC3043 , #731E5D)",
    ExtendedLinearGradient = "linear-gradient(125deg, #eb660c, #b93d50, #802b6a, #802b6a, #b93d50, #eb660c, #eb660c, #b93d50, #802b6a, #802b6a)",
}

export enum SocialColors {
    Facebook = "#3b5999",
    Messenger = "#0084ff",
    Twitter = "#55acee",
    LinkedIn = "#0077B5",
    Skype = "#00AFF0",
    Dropbox = "#007ee5",
    Vimeo = "#1ab7ea",
    YouTube = "#cd201f",
    Reddit = "#ff5700",
    Quora = "#b92b27",
    WhatsApp = "#25D366",
    Instagram = "#e4405f",
    Snapchat = "#FFFC00",
}
