import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Colors } from "./colors";

export enum OgilvieFont {
    Light = "Ogilvie Light, sans-serif",
    Regular = "Ogilvie Regular, sans-serif",
    SemiBold = "Ogilvie SemiBold, sans-serif",
    Bold = "Ogilvie Bold, sans-serif",
}

const gradientCss = {
    color: Colors.Purple,
    backgroundColor: Colors.Rose,
    backgroundImage: Colors.LinearGradient,
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    MozTextFillColor: "transparent",
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        backgroundColor: Colors.Transparent,
        backgroundImage: "none",
    },
};

export const mainTypography: TypographyOptions = {
    fontFamily: OgilvieFont.Regular,

    h1: {
        fontFamily: OgilvieFont.Light,
        fontSize: "4.2rem",
        textAlign: "left",
        paddingBottom: "1rem",
        fontVariantLigatures: "no-common-ligatures",
        ...gradientCss,
    },

    h2: {
        fontFamily: OgilvieFont.Light,
        fontSize: "3rem",
        textAlign: "left",
        paddingBottom: "0.8rem",
        fontVariantLigatures: "no-common-ligatures",
        ...gradientCss,
    },

    h3: {
        fontFamily: OgilvieFont.Light,
        fontSize: "2.2rem",
        textAlign: "left",
        paddingBottom: "0.5rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    h4: {
        fontFamily: OgilvieFont.Light,
        fontSize: "1.8rem",
        textAlign: "left",
        paddingBottom: "0.3rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    h5: {
        fontFamily: OgilvieFont.Light,
        fontSize: "1.3rem",
        textAlign: "left",
        paddingBottom: "0.4rem",
        fontVariantLigatures: "no-common-ligatures",
        ...gradientCss,
    },

    body1: {
        fontSize: "1.4rem",
        textAlign: "left",
        paddingBottom: "0.2rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    body2: {
        fontSize: "1rem",
        textAlign: "left",
        paddingBottom: "0.1rem",
        fontVariantLigatures: "no-common-ligatures",
    },
};

export const gradientTypography: TypographyOptions = {
    ...mainTypography,

    h1: {
        fontFamily: OgilvieFont.Light,
        fontSize: "4.2rem",
        textAlign: "left",
        color: Colors.White,
        paddingBottom: "1rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    h2: {
        ...mainTypography.h2,
        color: Colors.White,
    },

    h3: {
        ...mainTypography.h3,
        color: Colors.White,
    },

    h4: {
        ...mainTypography.h4,
        color: Colors.White,
    },

    h5: {
        ...mainTypography.h5,
        color: Colors.White,
    },

    body1: {
        ...mainTypography.body1,
        color: Colors.White,
    },

    body2: {
        ...mainTypography.body2,
        color: Colors.White,
    },
};
