import { alpha, hexToRgb } from "@mui/material";
import {
    append,
    complement,
    concat,
    contains,
    flip,
    init,
    last,
    pipe,
    propOr,
    replace,
    toLower,
    trim,
} from "ramda";
import { OrderDirection, OrderBy, VehicleAvailability } from "./enums";
import { CarOrVan } from "./interfaces/IVehicle";

export const round = (num: number, fractionDigits: number) =>
    parseFloat(num.toFixed(fractionDigits));

export const intToGbp = (x: number, opts: Intl.NumberFormatOptions = {}) => {
    try {
        const f = new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",

            ...opts,
        });
        return f.format(x).replace(".00", "");
    } catch (e) {
        console.info("Error in intToGbp");
        console.info(e);
        return "Unknown";
    }
};

export const rejectDisabled = (x) => x.disabled !== true;
export const rejectExperimental = pipe(
    propOr([], "tags"),
    complement(contains("experimental")),
);

export const parseFlag = pipe(
    (x) => x?.toString() ?? "",
    trim,
    toLower,
    flip(propOr(false))({
        0: false,
        1: true,
        f: false,
        t: true,
        false: false,
        true: true,
        n: false,
        y: true,
        no: false,
        yes: true,
    }),
);

// Formatted title to replace spacing and set to lower case
export const formatToHtmlSafeString = (stringIn: String) => {
    try {
        return stringIn.toLowerCase().replace(/ /g, "-");
    } catch (e) {
        return "";
    }
};

export const hexToRgba = (hex: string, opacity?: number) => {
    try {
        if (opacity == null) {
            return hexToRgb(hex);
        } else {
            return alpha(hexToRgb(hex), opacity);
        }
    } catch (e) {
        return "rgba(255,255,255,1)";
    }
};

export const formatTagsRight = (v: CarOrVan) => {
    if (v.type === "car") {
        return [`${v.bik.rate.value}% BIK - From £${v.bik.net.low} pcm`].filter(
            (x) => x !== null,
        );
    } else {
        return [];
    }
};

export const formatTagsLeft = (v: CarOrVan) => {
    return [
        v.type === "van" &&
            v?.model?.body &&
            `${v.model.body.replace("_", " ")}`,
        v?.battery?.tms && `${v.battery.tms} Battery`,
        v.type === "car" && v?.misc?.body,
        v.type === "van" && v?.misc?.segment && `Size: ${v.misc.segment}`,
        v?.drivetrain?.propulsion?.value && v.drivetrain.propulsion.value,
        v?.misc?.seats && `${v.misc.seats.value} ${v.misc.seats.unit}`,
        v.type === "van" && v.model.length ? `Length: ${v.model.length}` : null,
        v.type === "van" && v.model.height ? `Height: ${v.model.height}` : null,
        v.type === "van" ? v.model.gvw : null,
    ].filter(Boolean);
};

const NBSP = "\xa0";

export const convertToNbsp = replace(/ /g, NBSP);

export const isIe = (window: { document: { documentMode?: any } }): boolean => {
    return !!window.document.documentMode;
};

export const isIe11 = (window: {
    MSInputMethodContext?: any;
    document: { documentMode?: any };
}): boolean => {
    return !!window.MSInputMethodContext && !!window.document.documentMode;
};

export const removeHtmlTags = (htmlString: String) => {
    try {
        return htmlString.replace(/(<([^>]+)>)/gi, "");
    } catch (e) {
        return "";
    }
};

export const scrollToElement = (ref) => () => {
    window?.scrollTo({ top: ref?.current?.offsetTop - 48, behavior: "smooth" });
};

export const splitMenuItemsByHeader = (items: any[]) =>
    items.reduce(
        (agg: any[][], x: any) =>
            x.isHeader === true
                ? append([x], agg)
                : concat(init(agg), [append(x, last(agg))]),
        [],
    );

export const truncateString =
    (num: number, ellip: string = "…") =>
    (str: string | null | undefined) => {
        return str == null || str.length <= num
            ? str
            : str.slice(0, num + 1).replace(/\s+\S+$/, "") + ellip;
    };

export async function shareCar(
    car: string,
    id: number,
    openSnack: (b: boolean) => void,
) {
    const data = {
        title: car,
        text: `Check out the ${car} on Ogilvie Fleet`,
        url: `https://electric.ogilvie-fleet.co.uk/vehicle/${id}`,
    };
    try {
        await navigator.share(data);
        return false;
    } catch (err) {
        try {
            await navigator.clipboard.writeText(data.url);
            openSnack(true);
            return true;
        } catch (err) {
            try {
                window["clipboardData"].setData("Text", data.url);
                openSnack(true);
                return true;
            } catch (e) {
                return false;
            }
        }
    }
}

export const intIsEven = (val) => {
    if (val % 2 == 0) return true;
    else return false;
};

// castToVehicle functions
export const kmToMiles = (n: number | null) =>
    n == null ? null : Math.round(n / 1.609);

export const kWhPer100KmToWhPerMi = (n: number | null) =>
    n == null ? null : Math.round(n * 16.09344);

// 1kWh/100km => 1mi/1kWh
export const kWhPer100KmToMilePerkWh = (n: number | null) => {
    if (n == null || n === 0) {
        return n;
    } else {
        // kWh/100km
        const kmToMiMultiplier = 0.62137;
        // take the inverse - 100km/kWh
        const oneHundredKmPerkWh = Math.pow(n, -1);
        // Multiply by 100 to cancel out - km/kWh
        const kmPerkWh = oneHundredKmPerkWh * 100;
        // Convert to mi - mi/kWh
        const miPerkWh = kmPerkWh * kmToMiMultiplier;
        // return miPerkWh;
        return round(miPerkWh, 1);
    }
};

export const literPerKmToMiPerGL = (n: number) =>
    n == null ? null : Math.round(282.481 / n);

export const date = (d: string) => {
    if (d != null) {
        const date: string[] = d.split("-");
        return new Date(parseInt(date[1]), parseInt(date[0]));
    }
};

export const chargePlug = (x: string) => {
    switch (x) {
        case "Type 1":
            return "Type 1 (Yazaki - SAE J1772)";
        case "Type 2":
            return "Type 2 (Mennekes - IEC 62196)";
    }
};

const mapOrderDirectionToStr = {
    [OrderDirection.asc]: "Ascending",
    [OrderDirection.desc]: "Descending",
};

export const orderDirectionToString = (x: OrderDirection) =>
    mapOrderDirectionToStr[x] ?? x.toString();

const translate = (map) => (key) => key in map ? map[key] : key;

const drivetrainTypes = {
    BEV: "Battery EV",
    EREV: "Extended Range EV",
    PHEV: "Plugin Hybrid EV",
};

const drivetrainFuels = {
    D: "Electric & Diesel",
    E: "Electric only",
    P: "Electric & Petrol",
};

const drivetrainPropulsions = {
    AWD: "All wheel drive",
    Front: "Front wheel drive",
    Rear: "Rear wheel drive",
};

const sizes = {
    "S - Small": "Small",
    "M - Medium": "Medium",
    "L - Large": "Large",
    "XL - Extra Large": "Extra Large",
};

const bodies = {
    Doublecab: "Double Cab",
    Panel_Van: "Panel Van",
};

export const drivetrainType = translate(drivetrainTypes);
export const drivetrainFuel = translate(drivetrainFuels);
export const drivetrainPropulsion = translate(drivetrainPropulsions);
export const size = translate(sizes);
export const body = translate(bodies);

export const availabilityToString = (x: VehicleAvailability) => {
    switch (x) {
        case VehicleAvailability.AvailableForPreorder:
            return "Preorder";
        case VehicleAvailability.ComingSoon:
            return "Coming soon";
        case VehicleAvailability.ConceptVehicleCloseToPreorder:
            return "Concept vehicle";
        case VehicleAvailability.ConceptVehiclePreorderUnknown:
            return "Concept vehicle";
        case VehicleAvailability.ConceptVehicleWithPreorder:
            return "Concept vehicle";
        case VehicleAvailability.ConceptVehicleWithPreorderNoDateOfDelivery:
            return "Concept vehicle";
        case VehicleAvailability.InProduction:
            return "In production";
        case VehicleAvailability.Null:
            return "Unknown";
        case VehicleAvailability.OutOfProduction:
            return "Discontinued";
        case VehicleAvailability.Unavailable:
            return "Unavailable";
        case VehicleAvailability.Unknown:
            return "Unknown";
    }
};

export const availabilityDescToString = (x: VehicleAvailability) => {
    switch (x) {
        case VehicleAvailability.AvailableForPreorder:
            return "This vehicle is available for preorder. See availability for more details.";
        case VehicleAvailability.ComingSoon:
            return "This vehicle is available for preorder. See availability for more details.";
        case VehicleAvailability.ConceptVehicleCloseToPreorder:
            return "This vehicle is a concept vehicle and will soon be available for preorder.";
        case VehicleAvailability.ConceptVehiclePreorderUnknown:
            return "This vehicle is a concept vehicle with an unknown preorder date.";
        case VehicleAvailability.ConceptVehicleWithPreorder:
            return "This vehicle is a concept vehicle and available for preorder.";
        case VehicleAvailability.ConceptVehicleWithPreorderNoDateOfDelivery:
            return "This vehicle is a concept vehicle and available for preorder but does not have a date for delivery.";
        case VehicleAvailability.InProduction:
            return "This vehicle is available. See availability for more details.";
        case VehicleAvailability.Null:
            return "The status of this vehicle is unknown. See availability for more details.";
        case VehicleAvailability.OutOfProduction:
            return "This vehicle has been discontinued.";
        case VehicleAvailability.Unavailable:
            return "This vehicle is unavailable. It may have been discontinued or is not available in the UK.";
        case VehicleAvailability.Unknown:
            return "The status of this vehicle is unknown. See availability for more details.";
    }
};

export const isHighDensity = () =>
    typeof window === "undefined"
        ? true
        : (window.matchMedia &&
              (window.matchMedia(
                  "only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)",
              ).matches ||
                  window.matchMedia(
                      "only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)",
                  ).matches)) ||
          (window.devicePixelRatio && window.devicePixelRatio > 1.3);
