module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-PKV46TX","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","gtmAuth":"dE5ITQYArpIuuTuX9_kuZg","defaultDataLayer":{"platform":"gatsby"},"gtmPreview":"env-2","routeChangeEvent":"gatsby-route-change"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
