import { Colors } from "./colors";
import color from "color";
import { PaletteOptions } from "@mui/material/styles/createPalette";

export const palette: PaletteOptions = {
    mode: "light",
    primary: {
        light: color(Colors.Orange).lighten(0.5).toString(),
        main: Colors.Orange,
        dark: color(Colors.Orange).darken(0.5).toString(),
    },
    secondary: {
        light: color(Colors.Purple).lighten(0.5).toString(),
        main: Colors.Purple,
        dark: Colors.NavyBlue,
    },
    background: {
        default: Colors.LightGrey,
    },
};

export const darkPalette: PaletteOptions = {
    mode: "dark",
    primary: {
        main: Colors.White,
    },
    background: {
        default: Colors.ExtendedLinearGradient,
    },
};

export const gradientPalette: PaletteOptions = {
    mode: "dark",
    primary: {
        main: Colors.White,
    },
    background: {
        default: Colors.ExtendedLinearGradient,
    },
};
