export enum OrderDirection {
    asc,
    desc,
}

export enum OrderBy {
    price = "price",
    charging_speed = "charging_speed",
    efficiency = "efficiency",
    range = "range",
    battery_capacity = "battery_capacity",
    release_date = "release_date",
    top_speed = "top_speed",
}

export enum VehicleAvailability {
    Null = 999,
    Unavailable = null,
    OutOfProduction = 0,
    InProduction = 1,
    AvailableForPreorder = 2,
    ComingSoon = 3,
    ConceptVehicleWithPreorder = 12,
    ConceptVehicleCloseToPreorder = 13,
    ConceptVehicleWithPreorderNoDateOfDelivery = 22,
    ConceptVehiclePreorderUnknown = 23,
    Unknown = 91,
}

export enum Unit {
    Amps = "amps",
    GBP = "£",
    GramsPerKm = "g/km",
    Grams = "g",
    Horsepower = "Ps",
    Kilowatts = "kW",
    KilowattAC = "kW AC",
    Kilograms = "kg",
    KilowattDC = "kW DC",
    KilowattHours = "kWh",
    Liters = "L",
    LiterPerMi = "L/mi",
    Meters = "m",
    CubicMeters = "m³",
    Minutes = "mins",
    Miles = "mi",
    Millimeters = "mm",
    MilesPerHour = "mph",
    MilesPerGallon = "mpg",
    MilesPerKilowattHour = "mi/kWh",
    Newtonmeter = "N·ms",
    Percent = "%",
    Seconds = "secs",
    Seats = "Seats",
    Volts = "V",
    WattHoursPerMile = "Wh/mi",
    Years = "years",
}
